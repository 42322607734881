import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EquiservicesService {

  constructor() { }
 public equipopts = [
    {techid: 'tid0001', exists: 4, newcnt: 0 , Make: ' ', Model: ' '},
    {techid: 'tid0002', exists: 2, newcnt: 0 , Make: ' ', Model: ' '},
    {techid: 'tid0003', exists: 1, newcnt: 0 , Make: ' ', Model: ' '},
    {techid: 'tid0004', exists: 8, newcnt: 0 , Make: ' ', Model: ' '}
  ];
  public structures = [
    {
      'KEY': 'TELK',
      'CODE': 'TELK',
      'LEVEL': '0',
      'NAME': 'Telkom Contract',
      'PARENT': ''
    },
    {
      'KEY': 'TELK-10001',
      'CODE': '10001',
      'LEVEL': '1',
      'NAME': 'Campus1',
      'PARENT': 'TELK'
    },
    {
      'KEY': 'TELK-10002',
      'CODE': '10002',
      'LEVEL': '1',
      'NAME': 'Campus2',
      'PARENT': 'TELK'
    },
    {
      'KEY': 'TELK-10003',
      'CODE': '10003',
      'LEVEL': '1',
      'NAME': 'Campu3',
      'PARENT': 'TELK'
    },
    {
      'KEY': 'TELK-10001-01',
      'CODE': '01',
      'LEVEL': '2',
      'NAME': 'Building 1',
      'PARENT': 'TELK-10001'
    },
    {
      'KEY': 'TELK-10001-02',
      'CODE': '02',
      'LEVEL': '2',
      'NAME': 'Building 2',
      'PARENT': 'TELK-10001'
    },
    {
      'KEY': 'TELK-10001-03',
      'CODE': '03',
      'LEVEL': '2',
      'NAME': 'Building 3',
      'PARENT': 'TELK-10001'
    },
    {
      'KEY': 'TELK-10001-01-01',
      'CODE': '01',
      'LEVEL': '3',
      'NAME': 'Floor 1',
      'PARENT': 'TELK-10001-01'
    },
    {
      'KEY': 'TELK-10001-01-02',
      'CODE': '02',
      'LEVEL': '3',
      'NAME': 'Floor 2',
      'PARENT': 'TELK-10001-01'
    },
    {
      'KEY': 'TELK-10001-01-03',
      'CODE': '03',
      'LEVEL': '3',
      'NAME': 'Floor 3',
      'PARENT': 'TELK-10001-01'
    },
    {
      'KEY': 'TELK-10001-01-02-01',
      'CODE': '01',
      'LEVEL': '4',
      'NAME': 'Room 1',
      'PARENT': 'TELK-10001-01-02'
    },
    {
      'KEY': 'TELK-10001-01-02-02',
      'CODE': '02',
      'LEVEL': '4',
      'NAME': 'Room 2',
      'PARENT': 'TELK-10001-01-02'
    },
    {
      'KEY': 'TELK-10001-01-02-03',
      'CODE': '03',
      'LEVEL': '4',
      'NAME': 'Room 3',
      'PARENT': 'TELK-10001-01-02'
    },
    {
      'KEY': 'TELK-10002-01',
      'CODE': '01',
      'LEVEL': '2',
      'NAME': 'Building 1',
      'PARENT': 'TELK-10002'
    },
    {
      'KEY': 'TELK-10002-02',
      'CODE': '02',
      'LEVEL': '2',
      'NAME': 'Building 2',
      'PARENT': 'TELK-10002'
    },
    {
      'KEY': 'TELK-10002-03',
      'CODE': '03',
      'LEVEL': '2',
      'NAME': 'Building 3',
      'PARENT': 'TELK-10002'
    },
    {
      'KEY': 'TELK-10002-01-01',
      'CODE': '01',
      'LEVEL': '3',
      'NAME': 'Floor 1',
      'PARENT': 'TELK-10002-01'
    },
    {
      'KEY': 'TELK-10002-01-02',
      'CODE': '02',
      'LEVEL': '3',
      'NAME': 'Floor 2',
      'PARENT': 'TELK-10002-01'
    },
    {
      'KEY': 'TELK-10002-01-03',
      'CODE': '03',
      'LEVEL': '3',
      'NAME': 'Floor 3',
      'PARENT': 'TELK-10002-01'
    },
    {
      'KEY': 'TELK-10002-01-02-01',
      'CODE': '01',
      'LEVEL': '4',
      'NAME': 'Room 1',
      'PARENT': 'TELK-10002-01-02'
    },
    {
      'KEY': 'TELK-10002-01-02-02',
      'CODE': '02',
      'LEVEL': '4',
      'NAME': 'Room 2',
      'PARENT': 'TELK-10002-01-02'
    },
    {
      'KEY': 'TELK-10002-01-02-03',
      'CODE': '03',
      'LEVEL': '4',
      'NAME': 'Room 3',
      'PARENT': 'TELK-10002-01-02'
    },
    {
      'KEY': 'TELK-10003-01',
      'CODE': '01',
      'LEVEL': '2',
      'NAME': 'Building 1',
      'PARENT': 'TELK-10003'
    },
    {
      'KEY': 'TELK-10003-02',
      'CODE': '02',
      'LEVEL': '2',
      'NAME': 'Building 2',
      'PARENT': 'TELK-10003'
    },
    {
      'KEY': 'TELK-10003-03',
      'CODE': '03',
      'LEVEL': '2',
      'NAME': 'Building 3',
      'PARENT': 'TELK-10003'
    },
    {
      'KEY': 'TELK-10003-01-01',
      'CODE': '01',
      'LEVEL': '3',
      'NAME': 'Floor 1',
      'PARENT': 'TELK-10003-01'
    },
    {
      'KEY': 'TELK-10003-01-02',
      'CODE': '02',
      'LEVEL': '3',
      'NAME': 'Floor 2',
      'PARENT': 'TELK-10003-01'
    },
    {
      'KEY': 'TELK-10003-01-03',
      'CODE': '03',
      'LEVEL': '3',
      'NAME': 'Floor 3',
      'PARENT': 'TELK-10003-01'
    },
    {
      'KEY': 'TELK-10003-01-02-01',
      'CODE': '01',
      'LEVEL': '4',
      'NAME': 'Room 1',
      'PARENT': 'TELK-10003-01-02'
    },
    {
      'KEY': 'TELK-10003-01-02-02',
      'CODE': '02',
      'LEVEL': '4',
      'NAME': 'Room 2',
      'PARENT': 'TELK-10003-01-02'
    },
    {
      'KEY': 'TELK-10003-01-02-03',
      'CODE': '03',
      'LEVEL': '4',
      'NAME': 'Room 3',
      'PARENT': 'TELK-10003-01-02'
    }
   ] ;
   public context = ['TELK', '0', '0', '0', '0', '0', '0', '0', '0'] ;
   public currentlevel = 1 ;
}


