import { Component, OnInit } from '@angular/core';
import { EquiservicesService} from '../_services/equiservices.service';
import { Router } from '@angular/router';

 @Component({
  selector: 'app-locmaps',
  templateUrl: './locmaps.component.html',
  styleUrls: ['./locmaps.component.css']
})
export class LocmapsComponent implements OnInit {
  selectionopts = [];
  localcontext = [];
  structures = [];
  contextlevel = 1;
  PARENT = '';

  constructor(private equiservices: EquiservicesService,
              private router: Router ) {

               }
  ngOnInit() {
    this.structures = this.equiservices.structures ;
// tslint:disable-next-line: radix
    this.localcontext = this.structures.filter(struc => parseInt(struc.LEVEL) === this.contextlevel );
  }

  goUp( structurein: any ) {
    this.contextlevel = (this.contextlevel > 1) ? this.contextlevel - 1 : 1 ;
    this.localcontext = this.structures.filter(struc =>  parseInt(struc.LEVEL) === this.contextlevel && struc.KEY === this.PARENT );

    if (this.localcontext.length < 1 ) {
      this.router.navigate(['/']);
    }
    this.PARENT = this.localcontext[0].PARENT ;
  }

  goDown( structurein: any ) {
    this.contextlevel = (this.contextlevel < 4) ? this.contextlevel + 1 :  this.contextlevel ;
    // tslint:disable-next-line:max-line-length
// tslint:disable-next-line: radix
// tslint:disable-next-line: max-line-length
    this.localcontext = this.structures.filter(struc => ( parseInt(struc.LEVEL) === this.contextlevel ) && struc.PARENT === structurein.KEY );
    this.PARENT = this.localcontext[0].PARENT ;
  }
}
