import { Component, OnInit } from '@angular/core';
import { ActivatedRoute , Router } from '@angular/router';
import { EquiservicesService } from '@app/_services';

@Component({
  selector: 'app-equichoice',
  templateUrl: './equichoice.component.html',
  styleUrls: ['./equichoice.component.css']
})
export class EquichoiceComponent implements OnInit {
  techid = 'Unknown' ;
  editme: any ;
  constructor(private route: ActivatedRoute,
            private router: Router,
            private equiservices: EquiservicesService) { }

  ngOnInit() {
     this.route.queryParams.subscribe(techid => {
      this.techid = techid.techid ;
      let x = this.equiservices.equipopts.findIndex(eq => eq.techid === this.techid );
      this.editme = this.equiservices.equipopts[x] ;
     });
       }
  onBack() {
     //  this.equiservices.equipopts[2].exists = 99 ;
       this.router.navigate(['/equipopt']);
      }

}
