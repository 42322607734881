﻿import { Routes, RouterModule } from '@angular/router';
import { EquipoptComponent } from './equipopt/equipopt.component';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { AuthGuard } from './_guards';
import { EquichoiceComponent } from './equichoice/equichoice.component';
import { LocmapsComponent } from './locmaps/locmaps.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'equipopt', component: EquipoptComponent},
    { path: 'equichoice', component: EquichoiceComponent},
    { path: 'location', component: LocmapsComponent},
     // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
