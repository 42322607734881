import { Component, OnInit } from '@angular/core';
import { EquiservicesService } from '@app/_services';

@Component({
  selector: 'app-equipopt',
  templateUrl: './equipopt.component.html',
  styleUrls: ['./equipopt.component.css']
})
export class EquipoptComponent implements OnInit {
  selectionopts = [];
  equipopts = [];
  structures = [];

  constructor(private equiservices: EquiservicesService) { }
  ngOnInit() { this.equipopts = this.equiservices.equipopts ;
    this.structures = this.equiservices.structures ;
  }

  onSelect( selected: number, id: number) {
    this.selectionopts[id] = selected;
  }
  modMask( indexof: any) {
    let a = 1;
  }
}
